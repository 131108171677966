import ad from './lib/ad';
import gdprNotice from './modules/gdprNotice';
import searchForm from './modules/searchForm';

export default function() {
    $(function() {
        if ($('lottie-player').length) {
            import('@lottiefiles/lottie-player').then(LottiePlayer => {
                let newAnimation = new LottiePlayer();
                newAnimation.load($('lottie-player')[0]);
            });
        }

        gdprNotice.addGDPR();

        searchForm.init($('#newsearch'));

        ad.adResize($('.ad-950'), 950);
        ad.adResize($('.ad-725'), 725);
        ad.adResize($('.ad-728'), 728);
        ad.adResize($('.ad-300'), 300);
        
        $(window).on('resize', function() {
            ad.adResize($('.ad-950'), 950);
            ad.adResize($('.ad-725'), 725);
            ad.adResize($('.ad-728'), 728);
            ad.adResize($('.ad-300'), 300);
        });
    });
}